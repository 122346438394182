.navbar-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    backdrop-filter: blur(10px);
    @media(max-width: 992px){
        border-bottom: 1px solid rgba(256,256,256, 0.2);
    }
    // background-color: rgba(#0A0519, 0.3);

    .nav-links {
        gap: 44px;
        @media (max-width: 1280px){
            gap: 24px;
        }
        color: #fff;
        .nav-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 147px;
            min-height: 60px;
            font-family: 'Gilroy-Bold';
            letter-spacing: 2px;
            font-size: 18px;
            @media (max-width: 1280px){
                font-size: 16px;
            }
            cursor: pointer;
            span {
                padding: 2px 18px;
                position: relative;
                transition: color 500ms cubic-bezier(0.4, 0, 0.2, 1);
                &::before, &::after{
                    content: '';
                    width: 2px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    background-color: #fff;
                    transform: rotate(32deg);
                    box-shadow: 0 8px 12px #fff;
                    opacity: 0;
                    transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
                }
                &::before{
                    box-shadow: 0 8px 15px #fff;
                    left: calc(50% - 4px);
                } 
                &::after{
                    box-shadow: 0 -8px 15px #fff;
                    right: calc(50% - 4px);
                }
            }
            &:hover, &.active{
                span{
                    color: transparent;
                    background-image: linear-gradient(85.84deg, #00E5FF 9.77%, #FF00C7 48.93%, #FFC700 93.31%);
                    background-clip: text;
                    &::before{
                        left: 0;
                        opacity: 1;
                    } 
                    &::after{
                        opacity: 1;
                        right: 0;
                    }
                }
            }
        }
    }
}