
.toggle {
    position: relative;
    width: 42px;
    height: 42px;
    background: #fff;
    clip-path: polygon(8px 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%, 0 8px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    isolation: 'isolate';
    z-index: 999999;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(45deg, rgb(0, 229, 255) 27%, rgb(255, 0, 199) 52%, rgb(255, 199, 0) 80%);
        opacity: 0;
        z-index: -1;
        transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1),
    }
	span {
		position: absolute;
		width: 32px;
		height: 4px;
        left: 5px;
		background: #000;
		transition: 0.5s;
		&:nth-child(1) {
			transform: translateY(-9px);
			width: 24px;
			left: 5px;
		}
		&:nth-child(2) {
			transform: translateY(9px);
			width: 14px;
			left: 5px;
		}
	}
}
.toggle.active {
    
    &:before {
        opacity: 1;
    }
	span {
		&:nth-child(1) {
			width: 32px;
			transform: translateY(0) rotate(45deg);
			transition-delay: 0.125s;
		}
		&:nth-child(2) {
			width: 32px;
			transform: translateY(0) rotate(315deg);
			transition-delay: 0.25s;
		}
		&:nth-child(3) {
			transform: translateX(60px);
		}
	}
}

.sidebar-links {
    gap: 10px;
    color: #fff;
    .sidebar-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        min-height: 42px;
        font-family: 'Gilroy-Bold';
        letter-spacing: 2px;
        font-size: 18px;
        @media (max-width: 1280px){
            font-size: 16px;
        }
        cursor: pointer;
        span {
            padding: 2px 0;
            position: relative;
            transition: color 500ms cubic-bezier(0.4, 0, 0.2, 1);
            &::after{
                content: '';
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: -3px;
                background-color: #fff;
                box-shadow: 0 3px 12px #fff;
                opacity: 0;
                transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
            }
            &::after{
                box-shadow: 0 -8px 15px #fff;
                right: calc(50% - 4px);
            }
        }
        &:hover, &.active{
            span{
                color: transparent;
                background-image: linear-gradient(85.84deg, #00E5FF 9.77%, #FF00C7 48.93%, #FFC700 93.31%);
                background-clip: text;
                &::after{
                    opacity: 1;
                    right: 0;
                }
            }
        }
    }
}