.hero-section {
  min-height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  isolation: isolate;
  .interactive-bg{
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .particles{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -2;
  }
  .mesh-bg-wrapper{
    position: absolute;
    width: 100%;
    height: auto;
    top: -5%;
    left: 0;
    z-index: -1;
    overflow-x: hidden;
    overflow-y: auto;
    // opacity: 0.6;
    
    .mesh-bg{
      height: auto;
      width: 110%;
      transition: transform 0.3s cubic-bezier(.5, .6, .6, 1);
      animation: zoomOut 1.2s cubic-bezier(.5, .6, .6, 1);
    }
  }
  .round-mesh-bg-wrapper{
    position: absolute;
    width: 'auto';
    height: 80%;
    top: 50%;
    left: 50%;
    @media(max-width: 992px){
      max-width: 650px;
      width: 100%;
      height: auto;
    }
    transform: translate(-50%, -50%);
    z-index: -1;
    overflow: hidden;
    opacity: 0.4;

    .round-mesh-bg{
      height: 100%;
      width: auto;
      transition: transform 0.3s cubic-bezier(.5, .6, .6, 1);
      animation: zoomOut 1.2s cubic-bezier(.5, .6, .6, 1);
    }
  }
  .center-box {
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // pointer-events: none;
    
    @media(max-width: 992px){
      img{
        max-width: 80%;
      }
    }
    h1 {
      font-size: 7rem;
    }

    .key-points {
      margin-top: 60px;
      @media(max-width: 992px){
        margin-top: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 18px;
      }
      @media(max-width: 992px){
        max-width: 80%;
        flex-wrap: wrap;
      }
    }

    span {
      color: #fff;
      font-family: 'Gilroy-Bold';
      font-size: 2.5rem;
      padding: 0px 35px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.125rem;
      text-transform: uppercase;  
      @media(max-width: 992px){
        white-space: nowrap;
        font-size: 1.5rem;
        padding: 0px;
      }
    }
  }

  .scroll-down {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 45px;
    cursor: pointer;
  }

  /* Integrating the provided animation */
  .scroll-down .arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin-top: 10px;
    text-align: center;
    margin: 8% 0;
    animation: bounce 2s infinite;
  }

}

.work {
  background-color: #1e0b01;
  min-height: 100vh;
}

/* Keyframes for the bounce animation */
@keyframes zoomOut {
  from{
      opacity: 0;
      // transform: scale(1.1);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}